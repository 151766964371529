export enum ErrorCode {
  // Authentication error codes (100)
  InvalidPasswordError = "101",
  UserInActiveError = "102",
  UnKnownUserError = "103",
  NoTokenSpecified = "104",
  InsufficientRights = "105",
  TokenInvalid = "106",
  InvalidTenantError = "107",
  UserNotAllowedForTenant = "108",
  UserHasNoAdminRights = "109",
  NotFound = "110",
  InvalidDestinationAlias = "111",
  InvalidTripCode = "112",
  FileUploadFailed = "113",
  FileDeleteFailed = "114",
  SaveError = "115",
  InvalidPayload = "116",
  DataInconsistancy = "117",
  RecordHasBeenUpdated = "118",
  RecordHasRelatedData = "119",
  DuplicateAlias = "120",
  InvalidAliasChange = "121",
  BadRequest = "400",
}
export class InvalidTenantError extends Error {
  static errorType = "InvalidTenantError";
}
export class InSufficientTenantRights extends Error {
  static errorType = "InSufficientTenantRights";
}
export class InvalidInfoCardError extends Error {
  static errorType = "InvalidInfoCardError";
}
export class OptimisticLockError extends Error {
  static errorType = "OptimisticLockError";
}
export class DuplicateAliasError extends Error {
  static errorType = "DuplicateAliasError";
}
export class InfoCardHasRelatedDataError extends Error {
  static errorType = "InfoCardHasRelatedDataError";
}
export class InvalidAliasChangeError extends Error {
  static errorType = "InvalidAliasChangeError";
}
export class AreaHasRelatedDataError extends Error {
  static errorType = "AreaHasRelatedDataError";
}
export class InvalidUserError extends Error {
  static errorType = "InvalidUserError";
}
export class InvalidUserCredentials extends Error {
  static errorType = "InvalidUserCredentials";
}
export class UserInactiveError extends Error {
  static errorType = "UserInactiveError";
}
export class SessionExpiredError extends Error {
  static errorType = "SessionExpiredError";
}
export class InValidRefreshToken extends Error {
  static errorType = "InValidRefreshToken";
}
export class InvalidDestinationAliasError extends Error {
  static errorType = "InvalidDestinationAliasError";
}
export class InvalidTripCodeError extends Error {
  static errorType = "InvalidTripCodeError";
}
export class InvalidDestinationError extends Error {
  static errorType = "InvalidDestinationError";
}
export class DestinationHasRelatedDataError extends Error {
  static errorType = "DestinationHasRelatedDataError";
}
export class NotFoundError extends Error {
  static errorType = "NotFoundError";
}
export class InvalidDataError extends Error {
  static errorType = "InvalidDataError";
}
export class InvalidTokenError extends Error {
  static errorType = "InvalidTokenError";
}

export class FileUploadFailed extends Error {
  static errorType = "FileUploadFailed";
}
