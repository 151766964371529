import React, { useEffect, useState, memo } from "react";
import "cropperjs/dist/cropper.css";
import { Box, useMediaQuery } from "@chakra-ui/react";

import { AssetType } from "../Asset/types";
import { UploadingStage } from "./types";
import { SelectAsset } from "./SelectAsset";
import { CropAsset } from "./CropAsset";
import { DisplayAsset } from "./DisplayAsset";

type AssetEditorProps = {
  /** The crop box aspect ratio height */
  aspectRatioHeight: number;
  /** The crop box aspect ratio width */
  aspectRatioWidth: number;
  /** The container height which will be used to display the asset editor component: including the selection area, croping, or final image/video display */
  containerHeight?: string;
  /** The container width which will be used to display the asset editor component: including the selection area, croping, or final image/video display */
  containerWidth?: string;
  /** The image height required for quality validation, if user sends smaller value he/she will have a warning for the bad quality */
  imageHeight: number;
  /** The image width required for quality validation, if user sends smaller value he/she will have a warning for the bad quality */
  imageWidth: number;
  /** Function triggered when user started/finished the editing of an image. Can be used to show/hide validations while user is still editing. */
  onEditMode?: (userIsEditing: boolean) => void;
  /** Function triggered when user added/deleted his/hers image and the parent component can access the new asset */
  onUploadAsset: (asset: AssetType) => void;
  /** If the user already introduced an image/video and wants posibly to change it, 
  send to this property the previous saved asset and the component will dispay it in 3rd step display with the option to go to the first step to replace the previous intriduces asset */
  uploadedAsset?: AssetType;
  /** Set error state to true or false in order to have the component designed in error state */
  errorState?: boolean;
};

/** Asset Editor component will help you edit an asset introduced by the user.
 * It can have THREE states: SELECTING asset - CROPPING image asset - DISPLAYING asset.
 *
 * In CREATION mode:
 *
 * In case the user uploades an image, he/she will navigate in three steps:
 *  - Selection of image
 *  - Cropping the image
 *  - Displaying the image
 * In case the user uploades a video, he/she will navigate in two steps:
 *  - Selection of video
 *  - Displaying the video
 * In case the user embedes a video, he/she wikk navigate in two steps:
 *  - Selection of video URL
 *  - Displaying of the video
 *
 * In EDIT mode:
 *
 * If the user uploads a File OR URL, the AssetEditor will show this asset in Displaying state.
 */
const AssetEditorComponent: React.FC<AssetEditorProps> = ({
  aspectRatioHeight,
  aspectRatioWidth,
  containerHeight,
  containerWidth,
  errorState,
  imageHeight,
  imageWidth,
  onEditMode,
  onUploadAsset,
  uploadedAsset,
}) => {
  const [asset, setAsset] = useState<AssetType | undefined>(undefined);
  const [uploadingStage, setUploadingStage] =
    useState<UploadingStage>("select");
  const [warning, setWarning] = useState(false);
  const [isLargerThan576] = useMediaQuery("(min-width: 380px)");

  useEffect(() => {
    console.log("Luana uploadedAsset", uploadedAsset);
    if (uploadedAsset) {
      setAsset(uploadedAsset);
      setUploadingStage("display");
    }
  }, [uploadedAsset]);

  const handleAssetSelection = (asset: AssetType) => {
    setWarning(false);
    setAsset(asset);
    if (asset.mimeType === "image" && asset.file) {
      setUploadingStage("crop");
      onEditMode && onEditMode(true);
    } else {
      setUploadingStage("display");
      onUploadAsset(asset);
    }
  };

  const handleAcceptCrop = (croppedAsset: AssetType) => {
    setUploadingStage("display");
    setAsset(croppedAsset);
    onUploadAsset(croppedAsset || {});
    onEditMode && onEditMode(false);
    setWarning(false);
  };

  const handleCancelCrop = () => {
    setWarning(false);
    setUploadingStage("select");
    onEditMode && onEditMode(false);
  };

  const handleCancelAsset = () => {
    onUploadAsset({});

    setUploadingStage("select");
    setAsset(undefined);
    setWarning(false);
  };

  const handleWarningChange = (warning: boolean) => {
    setWarning(warning);
  };

  return (
    <Box
      width={containerWidth}
      height={containerHeight}
      marginBottom={warning && !isLargerThan576 ? "5" : warning ? "10" : "0"}
      display="block"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="relative"
    >
      {uploadingStage === "select" && (
        <SelectAsset
          onAssetSelection={handleAssetSelection}
          errorState={errorState}
        />
      )}
      {uploadingStage === "crop" && asset?.file && (
        <CropAsset
          file={asset.file}
          aspectRatioWidth={aspectRatioWidth}
          aspectRatioHeight={aspectRatioHeight}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          onAcceptModification={handleAcceptCrop}
          onCancelModification={handleCancelCrop}
          onWarrningChange={handleWarningChange}
        />
      )}
      {uploadingStage === "display" && asset && (
        <DisplayAsset asset={asset} onCancelAsset={handleCancelAsset} />
      )}
    </Box>
  );
};

export const AssetEditor = memo(AssetEditorComponent);
