import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IGetGuestTokenPayload } from "../entities/DTO-models/Authentication/IGetGuestTokenPayload";
import { ISwitchTenantPayload } from "../entities/DTO-models/Authentication/ISwitchTenantPayload";
import { ISwitchTenantResponse } from "../entities/DTO-models/Authentication/ISwitchTenantResponse";

export class InValidRefreshToken extends Error {}
export class NetworkError extends Error {}
export class mtbService {
  //TODO do getAccessTokeSilently before every protected call
  //TODO switch tenant -> getAccessTokenSilently, but pass the new tenantkey
  private static _authToken: string | undefined;

  private static _APIUrl(path: string): string {
    const url = process.env.NX_REACT_APP_MTBAPI_URL;
    let route = path;
    if (route.substring(0, 1) === "/") {
      route = route.substring(1);
    }
    if (url?.substring(url.length) === "/") {
      return `${url}api/${route}`;
    } else {
      return `${url}/api/${path}`;
    }
  }

  static async postGetGuestToken(
    payload: IGetGuestTokenPayload
  ): Promise<AxiosResponse> {
    const json: AxiosResponse = await axios.post(
      this._APIUrl("auth/getGuestToken"),
      payload,
      { withCredentials: true }
    );
    mtbService._authToken = json.data.token;
    return json;
  }

  static async postSwitchTenant(
    payload: ISwitchTenantPayload
  ): Promise<ISwitchTenantResponse | void> {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${mtbService._authToken}`,
        },
        withCredentials: true,
      };

      const json: AxiosResponse = await axios.post(
        this._APIUrl("auth/switchTenant"),
        payload,
        config
      );

      const data = json.data as ISwitchTenantResponse;
      return data;
    } catch (ex) {
      const axError = ex;
      if (axError.message.toLowerCase() === "network error") {
        throw new NetworkError();
      } else {
        throw ex;
      }
    }
  }

  // ... general get request wrapper
  static async getProtected(apiRoute: string, params?: string): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${mtbService._authToken}`,
      },
      params: params,
    };

    let json: AxiosResponse;
    try {
      json = await axios.get(this._APIUrl(apiRoute), config);
      return json;
    } catch (ex) {
      const axError = ex;
      if (axError.message.toLowerCase() === "network error") {
        throw new NetworkError();
      } else {
        throw ex;
      }
    }
  }

  // ... general post request wrapper
  static async postPutFormDataProtected(
    method: "post" | "put",
    apiRoute: string,
    data: FormData,
    params?: string
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${mtbService._authToken}`,
        "content-type": "multipart/form-data",
      },
      params: params,
    };

    let json: AxiosResponse;
    try {
      if (method === "post") {
        json = await axios.post(this._APIUrl(apiRoute), data, config);
      } else {
        json = await axios.put(this._APIUrl(apiRoute), data, config);
      }
      return json;
    } catch (ex: any) {
      const axError = ex;
      if (axError.message.toLowerCase() === "network error") {
        throw new NetworkError();
      } else {
        throw ex;
      }
    }
  }

  // ... general delete request wrapper
  static async deleteProtected(
    apiRoute: string,
    params?: string
  ): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${mtbService._authToken}`,
        "content-type": "multipart/form-data",
      },
      params: params,
    };

    let json: AxiosResponse;
    try {
      json = await axios.delete(this._APIUrl(apiRoute), config);
      return json;
    } catch (ex) {
      const axError = ex;
      if (axError.message.toLowerCase() === "network error") {
        throw new NetworkError();
      } else {
        throw ex;
      }
    }
  }

  static setAuth0AccessToken(auth0AccesToken?: string) {
    mtbService._authToken = auth0AccesToken;
  }
}
